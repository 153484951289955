import React from 'react'
import { Link } from "react-router-dom";


const TeachingEducation = () => {
  return (
    <>
      <main id="content" className="site-main">
        <section className="inner-banner-wrap mb-0">
          <div
            className="inner-baner-container"
            style={{ backgroundImage: "url(assets/img/busy-confident.jpg)" }}
          >
            <div className="container">
              <div className="inner-banner-content">
                <h3 className="inner-title">Teaching and Education</h3>
              </div>
            </div>
          </div>
        </section>
        <section className="course-result-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 left-content">
                <div className="pattern-overlay zigzag-patten" />
                <div className="feature-left-content">
                  <div className="title-divider" />
                  <h2 className="feature-course-title">
                    Teaching and Education !
                  </h2>
                  <p className="feature-course-desc">
                    The field of education encompasses various roles, including
                    teachers, administrators, principals, professors,
                    instructors, and support staff. Each role plays a crucial
                    part in ensuring effective learning and creating a conducive
                    environment for students.
                  </p>
                  <p className="feature-course-desc">
                    There are numerous teaching methods employed by educators to
                    engage learners and facilitate their understanding. These
                    methods can vary depending on the subject, age group, and
                    learning objectives. Examples include lectures, discussions,
                    group work, project-based learning, experiential learning,
                    and online or blended learning approaches.
                  </p>
                  <p className="feature-course-desc">
                    Educational institutions typically follow a curriculum that
                    outlines the subjects, content, and learning goals for
                    different grade levels or courses. Curricula are often
                    designed to align with educational standards set by
                    government bodies or educational organizations to ensure
                    consistency and quality in education.
                  </p>
                  <div className="feature-course-btn mt-lg-5">
                    <Link to="/contact" className="button-round-secondary">
                      JOIN US NOW
                    </Link>
                  </div>
                  <br />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="feature-right-content">
                  <div className="pattern-overlay c-patten" />
                  <div className="pattern-overlay circle-patten" />
                  <figure className="feature-image">
                    <img src="assets/img/Teaching and Education.jpg" alt="" />
                  </figure>
                  <br />
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default TeachingEducation