import React from "react";
import { Link } from "react-router-dom";


const HomeBanner = () => {
  return (
    <>
      <div id="content" className="site-main">
        <section className="home-banner d-flex align-items-end">
          <div className="container">
            <div className="row align-items-end">
              <div className=" banner-left col-md-6">
                <div className="image-overlay pattern-overlay" />
                <div className="banner-content">
                  <div className="title-divider" />
                  <div className="banner-title">
                    <h1>Providing Best Education For Brighter future</h1>
                  </div>
                  <div className="banner-button mt-lg-5">
                    <Link
                      to="/careercounselling"
                      className="button-round-secondary"
                    >
                      LEARN MORE
                    </Link>
                  </div>
                </div>
              </div>
              <div className=" banner-right col-md-6">
                <figure className="banner-img">
                  <div className="image-overlay-oval pattern-overlay" />
                  <img className="img-center" src="assets/img/educator-img33.png" alt="" />
                </figure>
              </div>
            </div>
          </div>
          <div className="overlay-image pattern-overlay" />
        </section>
      </div>
    </>
  );
};

export default HomeBanner;
