import React from 'react'

const GRE = () => {
  return (
    <>
      <section className="inner-banner-wrap">
        <div
          className="inner-baner-container"
          style={{ backgroundImage: "url(assets/img/three.jpg)" }}
        >
          <div className="container">
            <div className="inner-banner-content">
              <h3 className="inner-title">GRE</h3>
            </div>
          </div>
        </div>
      </section>

      <section className="home-goal-section categories-goal-section">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-7">
              <div className="inner-goal-image">
                <figure className="video-play-image">
                  <img src="assets/img/three.jpg" alt="" />
                  <div className="overlay02 overlay" />
                </figure>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="goal-content">
                <div className="pattern-overlay" />
                <div className="title-divider" />
                <p className="goal-info">
                  The GRE General Test stands for Graduate Record Examination.
                  It thoroughly evaluates a student's ability in terms of
                  analytical writing, mathematics, verbal reasoning, critical
                  thinking, and vocabulary.
                </p>
                <p className="goal-info">
                  GRE is the most widely accepted examination by foreign
                  universities to determine a candidate's eligibility for a
                  postgraduate program. Thus, clearing GRE can be considered the
                  gateway to studying in a foreign university. Countries like
                  the USA, Canada and Singapore accept GRE. Apart from graduate
                  schools, many business schools also accept GRE scores and may
                  convert them to equivalent GMAT scores, the generally accepted
                  exam score for management courses.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="mt-lg-5"></div>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
    </>
  );
}

export default GRE