import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <footer id="colophon" className="site-footer">
        <div className="footer-overlay" />
        <div className="top-footer">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-6">
                <aside className="widget widget_text img-textwidget">
                  <div className="footer-logo">
                    <Link to="/">
                      <img src="assets/img/123.png" alt="logo" />
                    </Link>
                  </div>
                  <div className="textwidget widget-text">
                    As an Education Consultant with 25 years of experience in
                    placing students in their dream colleges, we know exactly
                    what a student looking for studying abroad. Our Experts help
                    them to choose their dream colleges and study program.
                  </div>
                </aside>
                <div className="footer-social-links">
                  <ul>
                    <li>
                      <Link to="" target="_blank">
                        <i className="fab fa-facebook-f" aria-hidden="true" />
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank">
                        <i className="fab fa-twitter" aria-hidden="true" />
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank">
                        <i className="fab fa-instagram" aria-hidden="true" />
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank">
                        <i className="fab fa-youtube" aria-hidden="true" />
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <aside className="widget">
                  <h5 className="widget-title">Quick LInks</h5>
                  <ul>
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li>
                      <Link to="/howselectstudylocation">Study Abrod</Link>
                    </li>
                    <li>
                      <Link to="/careercounselling">Our Servuces</Link>
                    </li>
                  </ul>
                </aside>
              </div>
              <div className="col-lg-3 col-md-6">
                <aside className="widget">
                  <h5 className="widget-title">Contact Us</h5>
                  <ul>
                    <li>
                      <Link to="/contact">Contact Us</Link>
                    </li>
                    <li>
                      <Link to="/toeflIeltsts">Test Preparation</Link>
                    </li>
                    <li>
                      <Link to="/uk">Study Destinations</Link>
                    </li>
                    <li>
                      <Link to="/attendmocksession">Attend A Mock Session</Link>
                    </li>
                  </ul>
                </aside>
              </div>
              <div className="col-lg-3 col-md-6">
                <aside className="widget widget_text school-time">
                  <h5 className="widget-title">Open Hours</h5>
                  <span>
                    <i aria-hidden="true" className="far fa-clock" />
                    09:30 AM - 06:30 PM , Monday - Saturday
                  </span>
                  <Link
                    to="/contact"
                    className="button-round-secondary mt-lg-5 "
                  >
                    JOIN US NOW
                  </Link>
                </aside>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="bottom-footer">
            <div className="row">
              <div className="col-md-12">
                <div className="copy-right">
                  <p>
                    © 2023. Powered By
                    <a
                      className="text-white"
                      href="https://dexterous.in"
                      target="_blanck"
                    >
                      {" "}
                      dexterous.in
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
