import React from "react";
import { Link } from "react-router-dom";

const Contact = () => {
  return (
    <>
      <main id="content" className="site-main">
        <section className="inner-banner-wrap">
          <div
            className="inner-baner-container"
            style={{ backgroundImage: "url(assets/img/educator-img12.jpg)" }}
          >
            <div className="container">
              <div className="inner-banner-content">
                <h1 className="inner-title">Contact Us </h1>
              </div>
            </div>
          </div>
        </section>
        <div className="contact-page-section">
          <div className="container">
            <div className="contact-form-inner">
              <div className="pattern-overlay zigzag-patten" />
              <div className="row align-items-center">
                <div className="col-lg-6">
                  <div className="contact-detail-container">
                    <div className="section-head">
                      <div className="title-divider" />
                      <h2 className="section-title">Contact!</h2>
                    </div>
                    <div className="contact-details-list">
                      <ul>
                        <li>
                          <span className="icon">
                            <i
                              aria-hidden="true"
                              className="icon icon-phone1"
                            />
                          </span>
                          <div className="details-content">
                            <h5>Phone Number :</h5>

                            <span className="text-center mt-lg-3">
                              {" "}
                              <p className="mb-2">
                                <a
                                  href="tel:+91  9948199947"
                                  style={{ color: "black" }}
                                >
                                  (+91)  9948199947
                                </a>
                              </p>
                            </span>
                          </div>
                        </li>
                        <li>
                          <span className="icon">
                            <i
                              aria-hidden="true"
                              className="icon icon-map-marker1"
                            />
                          </span>
                          <div className="details-content">
                            <h5>Location Address :</h5>
                            <span className="mt-lg-3">
                              Mekhri Circle, Behind CBI Office Bellary Road,
                              Ganganagar Extension Bengaluru -560036
                            </span>
                          </div>
                        </li>
                        {/* <li>
                          <span className="icon">
                            <i
                              aria-hidden="true"
                              className="icon icon-envelope3"
                            />
                          </span>
                          <div className="details-content">
                            <h5>Email address :</h5>
                          </div>
                        </li> */}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="contact-from-wrap">
                    <div className="pattern-overlay circle-patten" />
                    <h5 className="cheakout-title text-center">
                      Fill in your details and we’ll call you back.
                    </h5>

                    <form className="contact-from">
                      <p>
                        <input
                          type="text"
                          name="name"
                          placeholder="First Name*"
                        />
                      </p>
                      <p>
                        <input
                          type="text"
                          name="name"
                          placeholder="Last Name*"
                        />
                      </p>
                      <p>
                        <input
                          type="number"
                          name="number"
                          placeholder=" Number*"
                        />
                      </p>
                      <p>
                        <input type="email" name="email" placeholder="Email*" />
                      </p>

                      <div className="col-sm-12">
                        <div className="form-group">
                          <label>
                            <abbr className="label-star"></abbr>
                          </label>
                          <select>
                            <option value={0}>Nearest Educasa office</option>
                            <option value={0}>Amritsar</option>
                            <option value={0}>Ahmedabad</option>
                            <option value={0}>Bangalore</option>
                            <option value={0}>Chandigarh</option>
                            <option value={0}>Chennai</option>
                            <option value={0}>Coimbatore</option>
                            <option value={0}>Delhi</option>
                            <option value={0}>Hyderabad</option>
                            <option value={0}>Indore</option>
                            <option value={0}>Jaipur</option>
                            <option value={0}>Other</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-sm-12">
                        <div className="form-group">
                          <label>
                            <abbr className="label-star"></abbr>
                          </label>
                          <select>
                            <option value={0}>
                              Preferred Study Destination
                            </option>
                            <option value={0}>Australia</option>
                            <option value={0}>UK</option>
                            <option value={0}>USA</option>
                            <option value={0}>Dubai</option>
                            <option value={0}>Italy</option>
                            <option value={0}>Other</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-sm-12">
                        <div className="form-group">
                          <label>
                            <abbr className="label-star"></abbr>
                          </label>
                          <select>
                            <option value={0}>
                              When do you plan to study?
                            </option>
                            <option value={0}>Jan-23</option>
                            <option value={0}>fab-23</option>
                            <option value={0}>mar-23</option>
                            <option value={0}>apr-23</option>
                            <option value={0}>may-23</option>
                            <option value={0}>jun-23</option>
                            <option value={0}>july-23</option>
                            <option value={0}>aug-23</option>
                            <option value={0}>sep-23</option>
                            <option value={0}>oct-23</option>
                            <option value={0}>nov-23</option>
                            <option value={0}>dec-23</option>
                          </select>
                        </div>
                      </div>
                      <Link className="width-full text-center" to="/">
                        <input
                          type="submit"
                          name="submit"
                          defaultValue="Submit Now"
                        />
                      </Link>
                    </form>
                  </div>
                </div>

                {/* <div className="carrer-page-section">
                  <div className="container">
                    <div className="vacancy-section">
                      <div className="section-head text-center">
                        <div className="row">
                          <div className="col-lg-8 offset-lg-2">
                            <div className="career-section-head">
                              <div className="title-divider-center" />
                              <h2 className="section-title">
                                Our Global Locations
                              </h2>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="vacancy-container">
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="vacancy-content-wrap">
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="vacancy-content">
                                    <div className="pattern-overlay zigzag-patten" />
                                    <h2 className="text-center">India</h2>
                                        <ul>
                                          <li>Bangalore</li>
                                          <li>Hyderabad</li>
                                          <li>Chennai</li>
                                          <li>Delhi</li>
                                          <li>Jaipur</li>
                                        </ul>
                                      
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="vacancy-content">
                                    <div className="pattern-overlay zigzag-patten" />

                                    <h2 className="text-center">America</h2>
                                    <ul>
                                      <li>Canada</li>
                                      <li>Colombia</li>
                                      <li>Argentina</li>
                                      <li>Uruguay</li>
                                      <li>USA</li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default Contact;
