import React from 'react'
import { Link } from "react-router-dom";
 

const HealthMedicine = () => {
  return (
    <>
      <main id="content" className="site-main">
        <section className="inner-banner-wrap mb-0">
          <div
            className="inner-baner-container"
            style={{ backgroundImage: "url(assets/img/busy-confident.jpg)" }}
          >
            <div className="container">
              <div className="inner-banner-content">
                <h3 className="inner-title">Health and Medicine</h3>
              </div>
            </div>
          </div>
        </section>
        <section className="course-result-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 left-content">
                <div className="pattern-overlay zigzag-patten" />
                <div className="feature-left-content">
                  <div className="title-divider" />
                  <h2 className="feature-course-title">
                    Health and Medicine !
                  </h2>
                  <p className="feature-course-desc">
                    Health refers to a state of complete physical, mental, and
                    social well-being, and not merely the absence of disease or
                    infirmity. It involves various factors such as genetics,
                    lifestyle choices, environmental conditions, and access to
                    healthcare services.
                  </p>
                  <p className="feature-course-desc">
                    Healthcare professionals are individuals trained and
                    licensed to provide medical care and services. They include
                    doctors, nurses, pharmacists, dentists, therapists, and
                    other specialized practitioners who diagnose, treat, and
                    prevent illnesses and promote overall health.
                  </p>
                  <p className="feature-course-desc">
                    Medicine is a vast field with numerous specialized areas.
                    Some common medical specialties include cardiology (heart),
                    dermatology (skin), endocrinology (hormonal disorders),
                    gastroenterology (digestive system), neurology (nervous
                    system), and pediatrics (children's health).
                  </p>
                  <div className="feature-course-btn mt-lg-5">
                    <Link to="/contact" className="button-round-secondary">
                      JOIN US NOW
                    </Link>
                  </div>
                  <br />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="feature-right-content">
                  <div className="pattern-overlay c-patten" />
                  <div className="pattern-overlay circle-patten" />
                  <figure className="feature-image">
                    <img src="assets/img/Health+and+Medicine.jpg" alt="" />
                  </figure>
                  <br />
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default HealthMedicine