import React from 'react'
import { Link } from "react-router-dom";


const AccountingFinance = () => {
  return (
    <>
      <main id="content" className="site-main">
        <section className="inner-banner-wrap mb-0">
          <div
            className="inner-baner-container"
            style={{ backgroundImage: "url(assets/img/busy-confident.jpg)" }}
          >
            <div className="container">
              <div className="inner-banner-content">
                <h3 className="inner-title">Accounting Finance</h3>
              </div>
            </div>
          </div>
        </section>
        <section className="course-result-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 left-content">
                <div className="pattern-overlay zigzag-patten" />
                <div className="feature-left-content">
                  <div className="title-divider" />
                  <h2 className="feature-course-title">
                    Financial Assistance !
                  </h2>
                  <p className="feature-course-desc">
                    Studying abroad is no doubt an expensive affair, and we
                    understand this. We work along with our students and their
                    families to understand their financial strengths and
                    requirements and guide them with the best possible
                    solutions.
                  </p>
                  <p className="feature-course-desc">
                    We also advise our students to set up a local bank account
                    on a priority basis on reaching their chosen country.
                  </p>
                  <p className="feature-course-desc">
                    This allows them easy access to funds which are to be used
                    for paying bills, and other living expenses. In case you
                    choose to work while studying, this also makes it easier for
                    your employer to deposit your salary.
                  </p>
                  <div className="feature-course-btn mt-lg-5">
                    <Link to="/contact" className="button-round-secondary">
                      JOIN US NOW
                    </Link>
                  </div>
                  <br />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="feature-right-content">
                  <div className="pattern-overlay c-patten" />
                  <div className="pattern-overlay circle-patten" />
                  <figure className="feature-image">
                    <img src="assets/img/busy-confident.jpg" alt="" />
                  </figure><br/>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default AccountingFinance