import React from "react";
import { Link } from "react-router-dom";

const Canada = () => {
  return (
    <>
      <section className="inner-banner-wrap">
        <div
          className="inner-baner-container"
          style={{ backgroundImage: "url(assets/img/educator-img12.jpg)" }}
        >
          <div className="container">
            <div className="inner-banner-content">
              <h3 className="inner-title">Study in Canada</h3>
            </div>
          </div>
        </div>
      </section>
      <section className="home-course-section" style={{ background: "white" }}>
        <div className="container">
          <div className="overlay-wrapper">
            <div className="inner-course-wrap">
              <div className="row d-flex justify-content-center justify-content-xl-start">
                <div className="col-sm-6 col-lg-4 pe-3 ps-3 pt-4 pt-lg-0 inner-course">
                  <article className="post">
                    <figure className="feature-image">
                      <img src="assets/img/Study-in.jpeg" alt="" />
                      <Link to="/" className="bookmark-icon">
                        <i aria-hidden="true" className="far fa-bookmark" />
                      </Link>
                    </figure>
                    <div className="entry-content">
                      <h4>
                        <Link to="">Study in Canada</Link>
                      </h4>
                      <p>
                        One of the most sought-after education destinations,
                        Canada is a land of knowledge and progress. Featured in
                        the list of best countries for quality of life, the
                        country of Canada opens its arms to people from around
                        the globe. Canada follows Russia as the second-largest
                        country in the world; but for a country its size, it is
                        sparsely populated. English and French are languages
                        spoken, making the country particularly bilingual.
                      </p>
                    </div>
                  </article>
                </div>
                <div className="col-sm-6 col-lg-4 pe-3 ps-3 pt-4 pt-lg-0 inner-course">
                  <article className="post">
                    <figure className="feature-image">
                      <img src="assets/img/in-Canada.jpg" alt="" />
                      <Link to="" className="bookmark-icon">
                        <i aria-hidden="true" className="far fa-bookmark" />
                      </Link>
                    </figure>
                    <div className="entry-content">
                      <h4>
                        <Link to="">Why study in Canada?</Link>
                      </h4>
                      <p>
                        There are many benefits to studying in Canada – students
                        get to learn from intellectual faculties, interact
                        culturally, and experience world-class education at
                        costs more budget-friendly when compared to UK and USA.
                        Canada was chosen by around 70,000 students as a
                        preferred education destination and globally enjoys the
                        3rd rank in attracting students from abroad. The number
                        of students being admitted into the Canadian education
                        system has tripled over the decade.
                      </p>
                    </div>
                  </article>
                </div>
                <div className="col-sm-6 col-lg-4 pe-3 ps-3 pt-4 pt-lg-0 inner-course">
                  <article className="post">
                    <figure className="feature-image">
                      <img src="assets/img/aa_article150-911255.jpg" alt="" />
                      <Link to="" className="bookmark-icon">
                        <i aria-hidden="true" className="far fa-bookmark" />
                      </Link>
                    </figure>
                    <div className="entry-content">
                      <h4>
                        <Link to="">Education System in Canada </Link>
                      </h4>
                      <p>
                        Globally, Canada enjoys the reputation of being one of
                        the most educated countries in the world. The education
                        system in the country is divided into primary,
                        secondary, and post-secondary or tertiary education.
                        Education provided is public, though, funded by
                        governments at the provincial, territorial and local
                        levels. Provincial influence is seen on education, and
                        the province oversees the curriculum.
                      </p>
                    </div>
                  </article>
                </div>
              </div>
            </div>
            <div className="overlay overlay-circle" />
            <div className="pattern-overlay" />
          </div>
        </div>
      </section>
    </>
  );
};

export default Canada;
