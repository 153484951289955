import React from 'react'
import { Link } from "react-router-dom";


const ArtDesign = () => {
  return (
    <>
      <main id="content" className="site-main">
        <section className="inner-banner-wrap mb-0">
          <div
            className="inner-baner-container"
            style={{ backgroundImage: "url(assets/img/y-study.jpg)" }}
          >
            <div className="container">
              <div className="inner-banner-content">
                <h3 className="inner-title">Art Design</h3>
              </div>
            </div>
          </div>
        </section>
        <section className="course-result-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 left-content">
                <div className="pattern-overlay zigzag-patten" />
                <div className="feature-left-content">
                  <div className="title-divider" />
                  <h2 className="feature-course-title">Art Design !</h2>
                  <p className="feature-course-desc">
                    Art refers to the expression or application of human
                    creative skill and imagination, typically in a visual form
                    such as painting, sculpture, or architecture. Design, on the
                    other hand, focuses on creating functional and aesthetic
                    solutions for specific purposes, such as graphic design,
                    industrial design, or interior design.
                  </p>
                  <p className="feature-course-desc">
                    Art often aims to evoke emotions, convey ideas, or provoke
                    thought, while design emphasizes functionality, usability,
                    and problem-solving. However, there can be overlap between
                    the two, as many designers incorporate artistic elements
                    into their work, and artists sometimes consider the
                    practical aspects of their creations.
                  </p>
                  <div className="feature-course-btn mt-lg-5">
                    <Link to="/contact" className="button-round-secondary">
                      JOIN US NOW
                    </Link>
                  </div>
                  <br />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="feature-right-content">
                  <div className="pattern-overlay c-patten" />
                  <div className="pattern-overlay circle-patten" />
                  <figure className="feature-image">
                    <img src="assets/img/y-study.jpg" alt="" />
                  </figure>
                  <br />
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default ArtDesign