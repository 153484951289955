import React from "react";
import { Link } from "react-router-dom";

const VisaGuidance = () => {
  return (
    <>
      <section className="inner-banner-wrap mb-0">
        <div
          className="inner-baner-container"
          style={{ backgroundImage: "url(assets/img/educator-img12.jpg)" }}
        >
          <div className="container">
            <div className="inner-banner-content">
              <h3 className="inner-title">Visa Guidance</h3>
            </div>
          </div>
        </div>
      </section>

      <main id="content" className="site-main">
        <div className="course-detail-section">
          <div className="pattern-overlay circle-patten" />
          <div className="course-detail-inner">
            <div className="container">
              <div className="row">
                <div className="col-lg-6">
                  <div className="course-detail-container">
                    <div className="tab-container">
                      <div className="responsive-tabs">
                        <div
                          id="nav-tab-content"
                          className="tab-content"
                          role="tablist"
                        >
                          <div
                            id="pane-A"
                            className="card tab-pane fade show active"
                            role="tabpanel"
                            aria-labelledby="tab-A"
                          >
                            <div
                              className="card-header"
                              role="tab"
                              id="heading-A"
                            >
                              <h5 className="mb-0">
                                <a
                                  data-bs-toggle="collapse"
                                  href="#collapse-A"
                                  aria-expanded="true"
                                  aria-controls="collapse-A"
                                ></a>
                              </h5>
                            </div>
                            <div
                              id="collapse-A"
                              className="collapse show"
                              data-bs-parent="#nav-tab-content"
                              role="tabpanel"
                              aria-labelledby="heading-A"
                            >
                              <div className="card-body">
                                <h5>Free Counselling</h5>
                                <p>
                                  You must have a current visa that specifies
                                  how long you are permitted to stay in the
                                  nation if you are a student travelling abroad
                                  to study. The optimal time to apply for a
                                  student visa is as soon as your admission to
                                  your preferred university has been confirmed,
                                  as processing times for visa applications vary
                                  from country to country. Regardless of the day
                                  your course is supposed to start, we advise
                                  beginning the procedure as soon as feasible.
                                </p>

                                <h5>Education Counsellor</h5>
                                <p>
                                  It is important to check with your education
                                  counsellor or the embassy website of the
                                  nation you are visiting as different countries
                                  have different visa requirements.
                                </p>
                                <h5 className="mt-lg-5">
                                  What you will be asked for include.
                                </h5>
                                <ul>
                                  <li>Proof of Admission.</li>
                                  <li>Valid Passport.</li>
                                  <li>
                                    Proof of Sufficient Funds to Meet Expenses.
                                  </li>
                                  <li>Proof of Proficiency in English.</li>
                                  <li>Passport Photographs.</li>
                                  <br />
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 order-lg-first mt-lg-5">
                  <div className="sidebar">
                    <div className="video-widget">
                      <img src="assets/img/businessman.jpg" alt="" />
                    </div>
                    <br />
                    <br />
                    <p>
                      At Educasa, we collaborate with our students to provide
                      them with guidance during the application process and to
                      keep them up to date on any recent developments. We also
                      assist you with scheduling appointments for the visa
                      interviews, interpreting, certifying, and getting all of
                      your paperwork ready for submission.
                    </p>
                    <p className="mt-lg-5">
                      <b>
                        Please fill out the adjacent form to schedule your free
                        counselling appointment.
                      </b>
                    </p>

                    <Link
                      to="/contact"
                      className="button-round-secondary mt-lg-5"
                    >
                      JOIN US NOW
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default VisaGuidance;
