import React from 'react'
import { Link } from "react-router-dom";


const EnvironmentalSciences = () => {
  return (
    <>
      <main id="content" className="site-main">
        <section className="inner-banner-wrap mb-0">
          <div
            className="inner-baner-container"
            style={{ backgroundImage: "url(assets/img/busy-confident.jpg)" }}
          >
            <div className="container">
              <div className="inner-banner-content">
                <h3 className="inner-title">Environmental Sciences</h3>
              </div>
            </div>
          </div>
        </section>
        <section className="course-result-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 left-content">
                <div className="pattern-overlay zigzag-patten" />
                <div className="feature-left-content">
                  <div className="title-divider" />
                  <h2 className="feature-course-title">
                    Environmental Sciences !
                  </h2>
                  <p className="feature-course-desc">
                    Investigates the chemical composition of natural resources,
                    pollution sources, and the effects of chemicals on
                    ecosystems and human health.
                  </p>
                  <p className="feature-course-desc">
                    Examines the interactions between living organisms and their
                    environment, including the study of ecosystems,
                    biodiversity, and the impacts of environmental changes on
                    species and populations.
                  </p>
                  <p className="feature-course-desc">
                    Focuses on understanding physical processes in the
                    environment, such as the behavior of light, heat transfer,
                    atmospheric dynamics, and the effects of climate change.
                  </p>
                  <div className="feature-course-btn mt-lg-5">
                    <Link to="/contact" className="button-round-secondary">
                      JOIN US NOW
                    </Link>{" "}
                  </div>
                  <br />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="feature-right-content">
                  <div className="pattern-overlay c-patten" />
                  <div className="pattern-overlay circle-patten" />
                  <figure className="feature-image">
                    <img src="assets/img/Environmental Sciences.jpg" alt="" />
                  </figure>
                  <br />
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default EnvironmentalSciences