import React from "react";
import { Link } from "react-router-dom";

const AccommodationAssistance = () => {
  return (
    <>
      <div>
        <section className="inner-banner-wrap mb-0">
          <div
            className="inner-baner-container"
            style={{ backgroundImage: "url(assets/img/education-academy.jpg)" }}
          >
            <div className="container">
              <div className="inner-banner-content">
                <h3 className="inner-title">Accommodation Assistance</h3>
              </div>
            </div>
          </div>
        </section>
        <section className="home-goal-section">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-7">
                <div className="inner-goal-image">
                  <figure className="video-play-image">
                    <img src="assets/img/education-academy.jpg" alt="" />
                    <div className="overlay02 overlay" />
                  </figure>
                </div>
              </div>
              <div className="col-lg-5">
                <div className="goal-content">
                  <div className="pattern-overlay" />
                  <div className="title-divider" />
                  <p className="goal-info">
                    We recognise that moving to a foreign nation, particularly
                    when you are young, might be intimidating. Making a new home
                    outside of your place of origin is a crucial component of
                    adjusting to the new culture and environment. The correct
                    lodging can make all the difference and influence how
                    smoothly you adjust to living abroad.
                  </p>
                  <p className="goal-info">
                    Our team of counsellors works with your parents and you to
                    determine the kind of housing that would be most appropriate
                    for you. While counselling you on choosing between on-campus
                    housing and off-campus housing, we take into account aspects
                    including safety, commuting time, cost, quality of life, and
                    college schedules.
                  </p>
                  <p>
                    <b>
                      We can assist you in locating international student-heavy
                      districts in the city of your choice. Please fill out the
                      adjacent form to schedule your free counselling
                      appointment.
                    </b>
                  </p>
                  <Link to="/contact" className="button-round-secondary">
                    JOIN US NOW
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
    </>
  );
};

export default AccommodationAssistance;
