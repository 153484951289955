import React from 'react'
import { Link } from "react-router-dom";


const PoliticalSocialSciences = () => {
  return (
    <>
      <main id="content" className="site-main">
        <section className="inner-banner-wrap mb-0">
          <div
            className="inner-baner-container"
            style={{ backgroundImage: "url(assets/img/busy-confident.jpg)" }}
          >
            <div className="container">
              <div className="inner-banner-content">
                <h3 className="inner-title">Political and Social Sciences</h3>
              </div>
            </div>
          </div>
        </section>
        <section className="course-result-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 left-content">
                <div className="pattern-overlay zigzag-patten" />
                <div className="feature-left-content">
                  <div className="title-divider" />
                  <h2 className="feature-course-title">
                    Political and Social Sciences !
                  </h2>
                  <p className="feature-course-desc">
                    Explores the ideas, concepts, and philosophies underlying
                    political systems and their application to real-world
                    contexts. It encompasses theories of democracy,
                    authoritarianism, liberalism, socialism, and other political
                    ideologies.
                  </p>
                  <p className="feature-course-desc">
                    Focuses on the comparative analysis of different political
                    systems, institutions, and processes across countries or
                    regions. It seeks to understand similarities, differences,
                    and patterns in political behavior, governance, and
                    policies.
                  </p>
                  <p className="feature-course-desc">
                    Examines interactions between nation-states, international
                    organizations, and non-state actors in the global arena. It
                    analyzes topics such as diplomacy, conflict resolution,
                    international law, globalization, and the role of
                    international institutions.
                  </p>
                  <div className="feature-course-btn mt-lg-5">
                    <Link to="/contact" className="button-round-secondary">
                      JOIN US NOW
                    </Link>
                  </div>
                  <br />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="feature-right-content">
                  <div className="pattern-overlay c-patten" />
                  <div className="pattern-overlay circle-patten" />
                  <figure className="feature-image">
                    <img
                      src="assets/img/Political and Social Sciences.jpg"
                      alt=""
                    />
                  </figure>
                  <br />
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default PoliticalSocialSciences