import React from 'react'
import { Link } from "react-router-dom";


const Australia = () => {
  return (
    <>
      <section className="inner-banner-wrap">
        <div
          className="inner-baner-container"
          style={{ backgroundImage: "url(assets/img/educator-img12.jpg)" }}
        >
          <div className="container">
            <div className="inner-banner-content">
              <h3 className="inner-title">Study in Australia</h3>
            </div>
          </div>
        </div>
      </section>

      <section className="home-course-section" style={{ background: "white" }}>
        <div className="container">
          <div className="overlay-wrapper">
            <div className="inner-course-wrap">
              <div className="row d-flex justify-content-center justify-content-xl-start">
                <div className="col-sm-6 col-lg-4 pe-3 ps-3 pt-4 pt-lg-0 inner-course">
                  <article className="post">
                    <figure className="feature-image">
                      <img src="assets/img/All-about.jpg" alt="" />
                      <Link to="/" className="bookmark-icon">
                        <i aria-hidden="true" className="far fa-bookmark" />
                      </Link>
                    </figure>
                    <div className="entry-content">
                      <h4>
                        <Link to="">Why Study in Australia?</Link>
                      </h4>
                      <p>
                        Popularly known to be the land of adorable kangaroos and
                        koala bears, Australia is also a welcoming country for
                        overseas students. Being the 6th largest country in the
                        world – Australia, is known for its diversity, beauty
                        and education. Globally, Australia has seven of the best
                        cities for students. Over the years, Australia has seen
                        an increased affinity among students from around the
                        globe. The education system offers its students a wide
                        variety of courses to choose from. Top universities, an
                        array of courses, student-friendly cities, and more, are
                        what attract students to Australia.
                      </p>
                    </div>
                  </article>
                </div>
                <div className="col-sm-6 col-lg-4 pe-3 ps-3 pt-4 pt-lg-0 inner-course">
                  <article className="post">
                    <figure className="feature-image">
                      <img src="assets/img/study-in-australia-2.jpg" alt="" />
                      <Link to="" className="bookmark-icon">
                        <i aria-hidden="true" className="far fa-bookmark" />
                      </Link>
                    </figure>
                    <div className="entry-content">
                      <h4>
                        <Link to="">Quality of life in Australia</Link>
                      </h4>
                      <p>
                        The Organization for Economic Cooperation and
                        Development (OECD) measures the quality of life on
                        predefined metrics, and Australia comfortably performs
                        above average in many parameters contributing to a
                        better life. Cities like Melbourne, Adelaide, Canberra,
                        Sydney and Brisbane rank high in the quality of life,
                        safety, purchasing power, health care and cost of
                        living. The QS Ranking for Best Cities for students’
                        features – Melbourne, Sydney, Brisbane, Canberra,
                        Adelaide, Perth, and the Gold Coast; Melbourne and
                        Sydney come in the top 10.
                      </p>
                      <br />
                      <p>
                        A full-time employee in Australia spends 14.4 hours on
                        average in leisure activities like socializing, sports,
                        and recreational activities. Income earned on an average
                        by an Australian household is higher than the average
                        maintained by the OECD.
                      </p>
                    </div>
                  </article>
                </div>
                <div className="col-sm-6 col-lg-4 pe-3 ps-3 pt-4 pt-lg-0 inner-course">
                  <article className="post">
                    <figure className="feature-image">
                      <img src="assets/img/australia-Students.jpg" alt="" />
                      <Link to="" className="bookmark-icon">
                        <i aria-hidden="true" className="far fa-bookmark" />
                      </Link>
                    </figure>
                    <div className="entry-content">
                      <h4>
                        <Link to="">
                          Study in Australia’s top-ranking universities
                        </Link>
                      </h4>
                      <p>
                        Australia welcomes the highest number of students in the
                        world. Tertiary courses contribute to the most number of
                        student intakes. China, India, and Nepal contribute
                        largely to overseas student enrolment.
                      </p>
                      <br />
                      <p>
                        Mixed Field Programmes, Management and Commerce, Science
                        and Engineering, Architecture and Built Environment,
                        Humanities, Education, Health, and Society and Culture
                        are courses that most excite international students to
                        pursue study in Australia. Most universities offer their
                        students the option of acquiring two degrees
                        simultaneously, through their dual degree programs. The
                        biggest advantage for a student is acquiring two degrees
                        in a shortened span.
                      </p>
                    </div>
                  </article>
                </div>
              </div>
            </div>
            <div className="overlay overlay-circle" />
            <div className="pattern-overlay" />
          </div>
        </div>
      </section>
    </>
  );
}

export default Australia