import React from 'react'
import { Link } from "react-router-dom";


const BiologicalLifeSciences = () => {
  return (
    <>
      <main id="content" className="site-main">
        <section className="inner-banner-wrap mb-0">
          <div
            className="inner-baner-container"
            style={{ backgroundImage: "url(assets/img/girl-with.jpg)" }}
          >
            <div className="container">
              <div className="inner-banner-content">
                <h3 className="inner-title">Biological & Life Sciences</h3>
              </div>
            </div>
          </div>
        </section>
        <section className="course-result-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 left-content">
                <div className="pattern-overlay zigzag-patten" />
                <div className="feature-left-content">
                  <div className="title-divider" />
                  <h2 className="feature-course-title">
                    Biological & Life Sciences!
                  </h2>
                  <p className="feature-course-desc">
                    The study of the structure and organization of living
                    organisms, including humans, animals, and plants. The study
                    of how living organisms function, including the mechanisms
                    and processes that enable them to carry out their vital
                    functions.
                  </p>
                  <p className="feature-course-desc">
                    The study of genes, heredity, and variation in living
                    organisms. It involves understanding how traits are
                    inherited, how genes function, and how they are regulated.
                    The study of biological processes at the molecular level,
                    focusing on the structure, function, and interactions of
                    molecules such as DNA, RNA, proteins, and other
                    macromolecules.
                  </p>
                  <div className="feature-course-btn mt-lg-5">
                    <Link to="/contact" className="button-round-secondary">
                      JOIN US NOW
                    </Link>
                  </div>
                  <br />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="feature-right-content">
                  <div className="pattern-overlay c-patten" />
                  <div className="pattern-overlay circle-patten" />
                  <figure className="feature-image">
                    <img src="assets/img/girl-with.jpg" alt="" />
                  </figure>
                  <br />
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default BiologicalLifeSciences