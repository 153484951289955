import React from 'react'
import { Link } from "react-router-dom";


const MarketingMediaCommunication = () => {
  return (
    <>
      <main id="content" className="site-main">
        <section className="inner-banner-wrap mb-0">
          <div
            className="inner-baner-container"
            style={{ backgroundImage: "url(assets/img/busy-confident.jpg)" }}
          >
            <div className="container">
              <div className="inner-banner-content">
                <h3 className="inner-title">
                  Marketing, Media and Communication
                </h3>
              </div>
            </div>
          </div>
        </section>
        <section className="course-result-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 left-content">
                <div className="pattern-overlay zigzag-patten" />
                <div className="feature-left-content">
                  <div className="title-divider" />
                  <h2 className="feature-course-title">
                    Marketing, Media and Communication !
                  </h2>
                  <p className="feature-course-desc">
                    Marketing involves various activities aimed at identifying,
                    anticipating, and satisfying customer needs and wants. It
                    encompasses market research, product development, pricing,
                    distribution, and promotion strategies. The goal of
                    marketing is to create awareness, generate demand, and build
                    strong customer relationships.
                  </p>
                  <p className="feature-course-desc">
                    Media refers to the channels or platforms used to deliver
                    information, entertainment, and advertising messages to the
                    public. Traditional media includes television, radio,
                    newspapers, and magazines, while digital media encompasses
                    websites, social media platforms, mobile apps, and streaming
                    services.
                  </p>
                  <div className="feature-course-btn mt-lg-5">
                    <Link to="/contact" className="button-round-secondary">
                      JOIN US NOW
                    </Link>
                  </div>
                  <br />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="feature-right-content">
                  <div className="pattern-overlay c-patten" />
                  <div className="pattern-overlay circle-patten" />
                  <figure className="feature-image">
                    <img
                      src="assets/img/Marketing, Media and Communication.jpg"
                      alt=""
                    />
                  </figure>
                  <br />
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default MarketingMediaCommunication