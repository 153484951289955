import React from "react";

const UniversityCourseSelection = () => {
  return (
    <>
      <section className="inner-banner-wrap">
        <div
          className="inner-baner-container"
          style={{ backgroundImage: "url(assets/img/choosing-right.jpg)" }}
        >
          <div className="container">
            <div className="inner-banner-content">
              <h3 className="inner-title">University Course Selection</h3>
            </div>
          </div>
        </div>
      </section>
      <section className="home-about-us">
        <div className="container">
          <div className="row">
            <div className="col-md-6 ">
              <div className="about-left-content">
                <figure className="figure-round-border">
                  <img src="assets/img/choosing-right.jpg" alt="" />
                </figure>

                <div className="title-divider" />
                <p className="about-desc">
                  At Educasa, our counsellors are dedicated to helping you
                  choose the programmes and universities that are most
                  compatible with your objectives and interests.
                </p>
                <p className="about-desc">
                  The task of deciding where and what to study might be
                  overwhelming with so many options for courses and
                  universities. Our staff of experts is here to assist you
                  during the entire process. Our counsellors utilise a
                  sophisticated information coordination system that is
                  constantly updated with the most latest information about
                  colleges, universities, and vocational schools.
                </p>
                <p className="about-desc">
                  We enhance a student's profile using market information,
                  analytics, and strategy while motivating them to push
                  boundaries and realise their full potential.
                </p>

                <p className="about-desc">
                  We also recognise that every student has unique needs. You may
                  thrive in a bustling city or have a preference for a calmer,
                  rural setting. Also, you can be seeking for a university with
                  a specific social club, sports team, or location that serves
                  as a commercial hub for a specific industry. By helping you
                  make a choice and streamlining your applications, Imagine
                  takes your needs and preferences into full consideration.
                </p>
                <br />
              </div>
            </div>
            <div className="col-md-6">
              <div className=" about-right-content">
                <p>
                  Also, we consider each individual's financial budget as well
                  as the proper timescales. Points of admission and intake times
                  vary between nations and organisations.
                </p>
                <br />
                <p>
                  The most well-liked study locations and a general notion of
                  their admissions are listed here. Please be aware that these
                  are approximate timeframes and could change from university to
                  institution.
                </p>
                <br />

                <figure className="figure-round-border">
                  <img src="assets/img/educator-img12-500px.jpg" alt="" />
                </figure>
              </div>
            </div>
          </div>
          <div className="pattern-overlay" />
        </div>
      </section>

      <div className="bg">
        <div className="container py-5">
          <div className="">
            <div className="col-lg-7 mx-auto bg-white rounded shadow">
              <div className="table-responsive">
                <table className="table table-fixed">
                  <thead>
                    <tr className="text-center  ">
                      <th scope="col" className="col-4 ">
                        Country
                      </th>
                      <th scope="col" className="col-4">
                        Intake
                      </th>
                      <th scope="col" className="col-4">
                        Month
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="col-4">UK</td>
                      <td className="col-4">Intake</td>
                      <td className="col-4">September / October</td>
                    </tr>
                    <tr>
                      <td className="col-4">-</td>
                      <td className="col-4">Intake</td>
                      <td className="col-4">January</td>
                    </tr>
                  </tbody>
                  <tbody>
                    <tr>
                      <td className="col-4">-</td>
                      <td className="col-4">Intake</td>
                      <td className="col-4">April</td>
                    </tr>
                    <tr>
                      <td className="col-4">Australia</td>
                      <td className="col-4">Summer Intake</td>
                      <td className="col-4">February / Early March</td>
                    </tr>
                  </tbody>
                  <tbody>
                    <tr>
                      <td className="col-4">-</td>
                      <td className="col-4">Spring Intake</td>
                      <td className="col-4">Late July / Early August</td>
                    </tr>
                  </tbody>
                  <tbody>
                    <tr>
                      <td className="col-4">Canada</td>
                      <td className="col-4">Fall Intake</td>
                      <td className="col-4">August / September</td>
                    </tr>
                  </tbody>
                  <tbody>
                    <tr>
                      <td className="col-4">-</td>
                      <td className="col-4">Spring Intake</td>
                      <td className="col-4">January / February</td>
                    </tr>
                    <tr>
                      <td className="col-4">-</td>
                      <td className="col-4">Summer Intake</td>
                      <td className="col-4">May / June</td>
                    </tr>
                  </tbody>
                  <tbody>
                    <tr>
                      <td className="col-4">New Zealand</td>
                      <td className="col-4">Intake</td>
                      <td className="col-4">February</td>
                    </tr>
                    <tr>
                      <td className="col-4">-</td>
                      <td className="col-4">Intake</td>
                      <td className="col-4">July</td>
                    </tr>
                  </tbody>
                  <tbody>
                    <tr>
                      <td className="col-4">Ireland</td>
                      <td className="col-4">Fall Intake</td>
                      <td className="col-4">September / October</td>
                    </tr>
                    <tr>
                      <td className="col-4">-</td>
                      <td className="col-4">Spring Intake</td>
                      <td className="col-4">January / February</td>
                    </tr>
                  </tbody>
                  <tbody>
                    <tr>
                      <td className="col-4">-</td>
                      <td className="col-4">February</td>
                      <td className="col-4">August /September</td>
                    </tr>
                    <tr>
                      <td className="col-4">-</td>
                      <td className="col-4">Spring Intake</td>
                      <td className="col-4">January</td>
                    </tr>
                  </tbody>
                  <tbody>
                    <tr>
                      <td className="col-4">-</td>
                      <td className="col-4">Summer Intake</td>
                      <td className="col-4">May</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UniversityCourseSelection;
