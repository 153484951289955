import React from 'react'
import { Link } from "react-router-dom";


const Engineering = () => {
  return (
    <>
      <main id="content" className="site-main">
        <section className="inner-banner-wrap mb-0">
          <div
            className="inner-baner-container"
            style={{ backgroundImage: "url(assets/img/busy-confident.jpg)" }}
          >
            <div className="container">
              <div className="inner-banner-content">
                <h3 className="inner-title">Engineering</h3>
              </div>
            </div>
          </div>
        </section>
        <section className="course-result-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 left-content">
                <div className="pattern-overlay zigzag-patten" />
                <div className="feature-left-content">
                  <div className="title-divider" />
                  <h2 className="feature-course-title">Engineering!</h2>
                  <p className="feature-course-desc">
                    Engineering is the application of scientific, mathematical,
                    and technical knowledge to design, create, and improve
                    structures, systems, machines, processes, and materials that
                    meet specific needs and solve problems.
                  </p>
                  <p className="feature-course-desc">
                    Engineers utilize various tools and software depending on
                    their field. These may include computer-aided design (CAD)
                    software, simulation tools, mathematical modeling software,
                    project management tools, and specialized equipment for
                    testing and analysis.
                  </p>
                  <p className="feature-course-desc">
                    Engineers often work in teams, collaborating with other
                    professionals such as architects, scientists, technicians,
                    and project managers to bring projects to fruition.
                    Effective communication, teamwork, and problem-solving
                    skills are crucial in engineering.
                  </p>
                  <div className="feature-course-btn mt-lg-5">
                    <Link to="/contact" className="button-round-secondary">
                      JOIN US NOW
                    </Link>
                  </div>
                  <br />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="feature-right-content">
                  <div className="pattern-overlay c-patten" />
                  <div className="pattern-overlay circle-patten" />
                  <figure className="feature-image">
                    <img src="assets/img/Design Engineering.jpg" alt="" />
                  </figure>
                  <br />
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default Engineering