import React from 'react'
import { Link } from "react-router-dom";

const ComputingIT = () => {
  return (
    <>
      <main id="content" className="site-main">
        <section className="inner-banner-wrap mb-0">
          <div
            className="inner-baner-container"
            style={{ backgroundImage: "url(assets/img/busy-confident.jpg)" }}
          >
            <div className="container">
              <div className="inner-banner-content">
                <h3 className="inner-title">Computing & IT</h3>
              </div>
            </div>
          </div>
        </section>
        <section className="course-result-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 left-content">
                <div className="pattern-overlay zigzag-patten" />
                <div className="feature-left-content">
                  <div className="title-divider" />
                  <h2 className="feature-course-title">Computing and IT</h2>
                  <p className="feature-course-desc">
                    Computing refers to the use of computers and related
                    technology to process, store, and manage information. It
                    encompasses various aspects such as hardware, software,
                    networking, and data management.
                  </p>
                  <p className="feature-course-desc">
                    IT is a broader term that includes the use of computers and
                    technology to handle and manipulate information. It
                    encompasses the design, development, implementation, and
                    management of computer systems, software applications, and
                    networks.
                  </p>
                  <p className="feature-course-desc">
                    Computer hardware refers to the physical components of a
                    computer system, including the central processing unit
                    (CPU), memory (RAM), storage devices (hard drives,
                    solid-state drives), input/output devices (keyboard, mouse,
                    monitor), and other peripheral devices (printers, scanners).
                  </p>
                  <div className="feature-course-btn mt-lg-5">
                    <Link to="/contact" className="button-round-secondary">
                      JOIN US NOW
                    </Link>
                  </div>
                  <br />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="feature-right-content">
                  <div className="pattern-overlay c-patten" />
                  <div className="pattern-overlay circle-patten" />
                  <figure className="feature-image">
                    <img src="assets/img/Computing and IT.jpg" alt="" />
                  </figure>
                  <br />
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default ComputingIT