import React from "react";
import { Link } from "react-router-dom";

const NewZealand = () => {
  return (
    <>
      <section className="inner-banner-wrap">
        <div
          className="inner-baner-container"
          style={{ backgroundImage: "url(assets/img/educator-img12.jpg)" }}
        >
          <div className="container">
            <div className="inner-banner-content">
              <h3 className="inner-title">Study in New Zealand</h3>
            </div>
          </div>
        </div>
      </section>
      <section className="home-course-section" style={{ background: "white" }}>
        <div className="container">
          <div className="overlay-wrapper">
            <div className="inner-course-wrap">
              <div className="row d-flex justify-content-center justify-content-xl-start">
                <div className="col-sm-6 col-lg-4 pe-3 ps-3 pt-4 pt-lg-0 inner-course">
                  <article className="post">
                    <figure className="feature-image">
                      <img src="assets/img/Study-In-New-Zealand.jpg" alt="" />
                      <Link to="/" className="bookmark-icon">
                        <i aria-hidden="true" className="far fa-bookmark" />
                      </Link>
                    </figure>
                    <div className="entry-content">
                      <h4>
                        <Link to="">Why study in New Zealand?</Link>
                      </h4>
                      <p>
                        New Zealand is among the world’s best study destinations
                        and as a student an individual can find its
                        internationally-renowned education system and unbeatable
                        way of life. New Zealand is a young country where
                        freedom, inventiveness and talent are held in high
                        regards than positions or rules. Study in New Zealand is
                        definitely going to be a unique experience full of
                        opportunities as the qualification here is recognized
                        and globally respected.
                      </p>
                    </div>
                  </article>
                </div>
                <div className="col-sm-6 col-lg-4 pe-3 ps-3 pt-4 pt-lg-0 inner-course">
                  <article className="post">
                    <figure className="feature-image">
                      <img src="assets/img/Increas.jpg" alt="" />
                      <Link to="" className="bookmark-icon">
                        <i aria-hidden="true" className="far fa-bookmark" />
                      </Link>
                    </figure>
                    <div className="entry-content">
                      <h4>
                        <Link to="">Education System in New Zealand</Link>
                      </h4>
                      <p>
                        Study in New Zealand supports students to solve issues,
                        process information, work with peers, and be creative
                        and innovative. A student at any level can study in New
                        Zealand and get a high-quality education that will help
                        them to attain their goals. Universities and
                        institutions in New Zealand usually have an academic
                        year with 2 to 3 semesters. They mainly offer two
                        academic intakes: one in January and other in July.
                        However, there are few institutions that offer multiple
                        intakes to their students in September as well as
                        November.Study in New Zealand comprises universities,
                        polytechnics and Wānanga.
                      </p>
                    </div>
                  </article>
                </div>
                <div className="col-sm-6 col-lg-4 pe-3 ps-3 pt-4 pt-lg-0 inner-course">
                  <article className="post">
                    <figure className="feature-image">
                      <img src="assets/img/new-zealand-670x670.jpg" alt="" />
                      <Link to="" className="bookmark-icon">
                        <i aria-hidden="true" className="far fa-bookmark" />
                      </Link>
                    </figure>
                    <div className="entry-content">
                      <h4>
                        <Link to="">
                          Tertiary Education System in New Zealand
                        </Link>
                      </h4>
                      <p>
                        New Zealand has many universities which are
                        state-funded, Institutes of Technology and Polytechnics
                        (ITPs) which have been merged into the New Zealand
                        Institute of Skills and Technology (NZIST), and many
                        Private Training Establishments (PTEs), which include
                        English language schools.
                      </p>
                    </div>
                  </article>
                </div>
              </div>
            </div>
            <div className="overlay overlay-circle" />
            <div className="pattern-overlay" />
          </div>
        </div>
      </section>
    </>
  );
};

export default NewZealand;
