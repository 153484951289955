import React from 'react'
import { Link } from "react-router-dom";


const Economics = () => {
  return (
    <>
      <main id="content" className="site-main">
        <section className="inner-banner-wrap mb-0">
          <div
            className="inner-baner-container"
            style={{ backgroundImage: "url(assets/img/Economics.jpg)" }}
          >
            <div className="container">
              <div className="inner-banner-content">
                <h3 className="inner-title">Economics</h3>
              </div>
            </div>
          </div>
        </section>
        <section className="course-result-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 left-content">
                <div className="pattern-overlay zigzag-patten" />
                <div className="feature-left-content">
                  <div className="title-divider" />
                  <h2 className="feature-course-title">Economics !</h2>
                  <p className="feature-course-desc">
                    Microeconomics focuses on the behavior of individual
                    economic agents such as households, firms, and markets. It
                    examines how individual decisions regarding production,
                    consumption, and pricing affect resource allocation.
                  </p>
                  <p className="feature-course-desc">
                    Macroeconomics deals with the overall functioning and
                    behavior of an economy as a whole. It examines aggregates
                    such as national income, employment, inflation, and economic
                    growth. Macroeconomists analyze factors that influence the
                    overall performance of economies and propose policies to
                    stabilize and promote economic growth.
                  </p>
                  <p className="feature-course-desc">
                    The supply and demand framework is fundamental to economics.
                    Supply refers to the quantity of a good or service that
                    producers are willing to offer at a given price, while
                    demand refers to the quantity of that good or service that
                    consumers are willing to buy at a given price. The
                    interaction of supply and demand determines market
                    equilibrium and prices.
                  </p>
                  <div className="feature-course-btn mt-lg-5">
                    <Link to="/contact" className="button-round-secondary">
                      JOIN US NOW
                    </Link>
                  </div>
                  <br />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="feature-right-content">
                  <div className="pattern-overlay c-patten" />
                  <div className="pattern-overlay circle-patten" />
                  <figure className="feature-image">
                    <img src="assets/img/Economics.jpg" alt="" />
                  </figure>
                  <br />
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default Economics