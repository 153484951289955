import React from "react";

const HomeAbout = () => {
  return (
    <>
      <section className="home-about-us">
        <div className="container">
          <div className="row">
            <div className="col-md-6 ">
              <div className="about-left-content">
                <figure className="figure-round-border">
                  <img src="assets/img/schoolgirl.png" alt />
                </figure>

                <h2 className="about-title">About !</h2>
                <p className="about-desc">
                  The Educasa. An all-in-one resource for studying abroad. We
                  don't just offer advice; we also make sure to pursue your
                  goals. In just a few years of pure effort and dedication, we
                  have succeeded in building a strong student network of 10k+
                  across the globe. Our journey has been nothing short of epic,
                  starting with a small team of just 4 people in a modest office
                  in Bombay and developing to a dedicated, ever-expanding staff
                  across 5 offices spread out over Mumbai and Pune.
                </p>
                <p className="about-desc">
                  Since we firmly think that you only get one chance to make a
                  good first impression, we approach your applications with this
                  mentality. Our success is determined by the quantity of good
                  comments we receive, as we work hard to provide quality and
                  honesty.
                </p>

                <div className="skill-container">
                  <div className="skill-wrapper">
                    <h6 className="skill-titile">Practical Knowledge</h6>
                    <div className="progress-wrapper">
                      <div
                        className="ab-progress example"
                        data-progress
                        data-value={94}
                      />
                    </div>
                  </div>
                  <div className="skill-wrapper">
                    <h6 className="skill-titile">Result</h6>
                    <div className="progress-wrapper">
                      <div
                        className="ab-progress example"
                        data-progress
                        data-value={99}
                      />
                    </div>
                  </div>
                  <div className="skill-wrapper">
                    <h6 className="skill-titile">Happy Students</h6>
                    <div className="progress-wrapper">
                      <div
                        className="ab-progress example"
                        data-progress
                        data-value={97}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className=" about-right-content">
                <div className="title-divider" />

                <p className="about-desc">
                  We steadfastly look for methods to make every student's time
                  at Educasa easy because we utterly reject the notion of
                  "settling" for anything. We thrive on ensuring that you end up
                  in the greatest location because our crew is brimming with
                  passion and expertise, and many of them have studied and
                  worked abroad.
                </p>
                <p className="about-desc">
                  We are really committed to each student's success, and their
                  development is the centre of both our existence and our
                  progress. We work hard every day to make sure you have the
                  easiest, most individualised experience possible throughout
                  this crucial decision-making period. We work to make the
                  journey as exciting as the destination.
                </p>
                <br />

                <figure className="figure-round-border">
                  <img src="assets/img/educator-img12-500px.jpg" alt />
                </figure>
              </div>
            </div>
          </div>
          <div className="pattern-overlay" />
        </div>
      </section>
      <br />
    </>
  );
};

export default HomeAbout;
