import React from 'react'
import { Link } from "react-router-dom";


const HospitalityTourism = () => {
  return (
    <>
      <main id="content" className="site-main">
        <section className="inner-banner-wrap mb-0">
          <div
            className="inner-baner-container"
            style={{ backgroundImage: "url(assets/img/busy-confident.jpg)" }}
          >
            <div className="container">
              <div className="inner-banner-content">
                <h3 className="inner-title">Hospitality and Tourism</h3>
              </div>
            </div>
          </div>
        </section>
        <section className="course-result-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 left-content">
                <div className="pattern-overlay zigzag-patten" />
                <div className="feature-left-content">
                  <div className="title-divider" />
                  <h2 className="feature-course-title">
                    Hospitality and Tourism !
                  </h2>
                  <p className="feature-course-desc">
                    The hospitality industry includes businesses that provide
                    services to guests or customers who are away from home. It
                    encompasses various sectors, such as hotels, restaurants,
                    resorts, event planning, theme parks, cruise lines, and
                    catering services.
                  </p>
                  <p className="feature-course-desc">
                    The hospitality industry focuses on providing a comfortable
                    and enjoyable experience for guests. Services offered
                    include accommodations, food and beverage services,
                    entertainment, leisure activities, and customer support.
                  </p>
                  <p className="feature-course-desc">
                    This sector comprises hotels, motels, resorts, bed and
                    breakfasts, hostels, and other establishments that offer
                    lodging facilities to travelers. They vary in terms of size,
                    amenities, and target markets (e.g., luxury hotels, budget
                    accommodations).
                  </p>
                  <div className="feature-course-btn mt-lg-5">
                    <Link to="/contact" className="button-round-secondary">
                      JOIN US NOW
                    </Link>
                  </div>
                  <br />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="feature-right-content">
                  <div className="pattern-overlay c-patten" />
                  <div className="pattern-overlay circle-patten" />
                  <figure className="feature-image">
                    <img src="assets/img/Hospitality and Tourism.jpg" alt="" />
                  </figure>
                  <br />
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default HospitalityTourism