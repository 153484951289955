import React from "react";
import { Link } from "react-router-dom";

const France = () => {
  return (
    <>
      <section className="inner-banner-wrap">
        <div
          className="inner-baner-container"
          style={{ backgroundImage: "url(assets/img/educator-img12.jpg)" }}
        >
          <div className="container">
            <div className="inner-banner-content">
              <h3 className="inner-title">Study in France</h3>
            </div>
          </div>
        </div>
      </section>
      <section className="home-course-section" style={{ background: "white" }}>
        <div className="container">
          <div className="overlay-wrapper">
            <div className="inner-course-wrap">
              <div className="row d-flex justify-content-center justify-content-xl-start">
                <div className="col-sm-6 col-lg-4 pe-3 ps-3 pt-4 pt-lg-0 inner-course">
                  <article className="post">
                    <figure className="feature-image">
                      <img src="assets/img/French-students.jpg" alt="" />
                      <Link to="/" className="bookmark-icon">
                        <i aria-hidden="true" className="far fa-bookmark" />
                      </Link>
                    </figure>
                    <div className="entry-content">
                      <h4>
                        <Link to="">Study in France</Link>
                      </h4>
                      <p>
                        The country of France is home to a 60 million people.
                        France is one of Europe’s largest countries. Seen as the
                        most popular tourist destination, France is adored for
                        its beauty, climate, landscapes, museums, galleries, and
                        food. The French love to cook. France can also be
                        described as the fashion capital. There are 22 regions
                        located in France; each region is further divided into
                        departments.
                      </p>
                    </div>
                  </article>
                </div>
                <div className="col-sm-6 col-lg-4 pe-3 ps-3 pt-4 pt-lg-0 inner-course">
                  <article className="post">
                    <figure className="feature-image">
                      <img src="assets/img/FRENCH-UNIVERSITY.jpg" alt="" />
                      <Link to="" className="bookmark-icon">
                        <i aria-hidden="true" className="far fa-bookmark" />
                      </Link>
                    </figure>
                    <div className="entry-content">
                      <h4>
                        <Link to="">Why study in France?</Link>
                      </h4>
                      <ul>
                        <li>
                          A student is offered a breadth of courses to pursue
                          across undergraduate and postgraduate studies.
                        </li>
                        <li>
                          France is home to some of the most prestigious
                          universities in the world. Students have a chance to
                          learn from numerous universities offering world-class
                          education.
                        </li>
                        <li>
                          An Indian student can apply for over 20 French
                          universities from the comfort of their homes by
                          filling one application form.
                        </li>
                        <li>
                          There are over 3,500 institutions of higher education
                          in France which include –
                        </li>
                        <li>Publicly-funded universities</li>
                        <li>Parallel Grandes Ecoles system</li>
                        <li>Schools of architecture</li>
                        <li>
                          Institutions offering specialized courses in hotel
                          management, tourism, culinary arts, etc.
                        </li>
                      </ul>
                    </div>
                  </article>
                </div>
                <div className="col-sm-6 col-lg-4 pe-3 ps-3 pt-4 pt-lg-0 inner-course">
                  <article className="post">
                    <figure className="feature-image">
                      <img src="assets/img/istockphoto1111.jpg" alt="" />
                      <Link to="" className="bookmark-icon">
                        <i aria-hidden="true" className="far fa-bookmark" />
                      </Link>
                    </figure>
                    <div className="entry-content">
                      <h4>
                        <Link to="">Top courses in France</Link>
                      </h4>
                      <p>
                        France is known for the education it imparts to its
                        students. Universities of France welcome international
                        students; this is a reason why its university campuses
                        ooze with diversity. La Sorbonne, Frances’ first
                        university received students from around Europe to
                        pursue education in theology, medicine and law.
                      </p>
                      <ul>
                        <li>Data Analytics</li>
                        <li>Big Data</li>
                        <li>International Law</li>
                        <li></li>
                        <li>Business Studies (MiM, MSc, MBA)</li>
                        <li>Hospitality & Tourism Management</li>
                        <li>IT & Computer Science</li>
                        <li>Aerospace Engineering</li>
                        <li>Life & Biomedical Sciences</li>
                      </ul>
                    </div>
                  </article>
                </div>
              </div>
            </div>
            <div className="overlay overlay-circle" />
            <div className="pattern-overlay" />
          </div>
        </div>
      </section>
    </>
  );
};

export default France;
