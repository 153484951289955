import React from "react";
import { Link } from "react-router-dom";

const Ireland = () => {
  return (
    <>
      <section className="inner-banner-wrap">
        <div
          className="inner-baner-container"
          style={{ backgroundImage: "url(assets/img/educator-img12.jpg)" }}
        >
          <div className="container">
            <div className="inner-banner-content">
              <h3 className="inner-title">Study in Ireland</h3>
            </div>
          </div>
        </div>
      </section>
      <section className="home-course-section" style={{ background: "white" }}>
        <div className="container">
          <div className="overlay-wrapper">
            <div className="inner-course-wrap">
              <div className="row d-flex justify-content-center justify-content-xl-start">
                <div className="col-sm-6 col-lg-4 pe-3 ps-3 pt-4 pt-lg-0 inner-course">
                  <article className="post">
                    <figure className="feature-image">
                      <img src="assets/img/female.jpg" alt="" />
                      <Link to="/" className="bookmark-icon">
                        <i aria-hidden="true" className="far fa-bookmark" />
                      </Link>
                    </figure>
                    <div className="entry-content">
                      <h4>
                        <Link to="">Study in Ireland</Link>
                      </h4>
                      <p>
                        Placed 13th on the Global Peace Index, Ireland has come
                        to be known as a land of serenity, tranquility, culture
                        and education; no doubt that it enjoys the preference of
                        Indian students. Ireland supports growth, progress and
                        despite its colonial history – the Irish are welcoming;
                        diversity is not alien to the country. Ireland is a safe
                        country and its natives are friendly, for this very
                        reason it once was rewarded the friendliest place on
                        earth.
                      </p>
                    </div>
                  </article>
                </div>
                <div className="col-sm-6 col-lg-4 pe-3 ps-3 pt-4 pt-lg-0 inner-course">
                  <article className="post">
                    <figure className="feature-image">
                      <img src="assets/img/1580639207.jpg" alt="" />
                      <Link to="" className="bookmark-icon">
                        <i aria-hidden="true" className="far fa-bookmark" />
                      </Link>
                    </figure>
                    <div className="entry-content">
                      <h4>
                        <Link to="">Why Study In Ireland?</Link>
                      </h4>
                      <p>
                        The European Union has had a major influence on
                        Ireland’s educational tradition. Irish Universities are
                        funded by their State Governments. Dublin, Ireland’s
                        capital and its largest city is house to five of the
                        country’s best universities. The education system for
                        higher studies comprises of the university sector,
                        Institutes of technology and private and independent
                        colleges. Ireland is an English speaking country, thus,
                        making it a preferred destination for students to study
                        abroad. An acceptance into an Irish university is based
                        on strong academic performances in national examinations
                        and the aptitude of English test.
                      </p>
                    </div>
                  </article>
                </div>
                <div className="col-sm-6 col-lg-4 pe-3 ps-3 pt-4 pt-lg-0 inner-course">
                  <article className="post">
                    <figure className="feature-image">
                      <img src="assets/img/study-ireland-800x534.jpg" alt="" />
                      <Link to="" className="bookmark-icon">
                        <i aria-hidden="true" className="far fa-bookmark" />
                      </Link>
                    </figure>
                    <div className="entry-content">
                      <h4>
                        <Link to="">Quality of education </Link>
                      </h4>
                      <p>
                        Ireland’s reputation for quality education stems from
                        its excellence. 9 courses offered by Irish colleges rank
                        in the top 50’s of their subject and appear in the
                        recent QS World University Ranking by Subjects. Courses
                        are designed to meet the requirements of students; this
                        makes study in Ireland attract an overseas audience.
                        Study in Ireland for Indian students after 12th is
                        usually three to four years, in courses offering
                        bachelor degrees. The quality of education contributes
                        to the economic growth of the country.
                      </p>
                    </div>
                  </article>
                </div>
              </div>
            </div>
            <div className="overlay overlay-circle" />
            <div className="pattern-overlay" />
          </div>
        </div>
      </section>
    </>
  );
};

export default Ireland;
