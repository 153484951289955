import React from 'react'
import { Link } from "react-router-dom";


const USA = () => {
  return (
    <>
      <section className="inner-banner-wrap">
        <div
          className="inner-baner-container"
          style={{ backgroundImage: "url(assets/img/educator-img12.jpg)" }}
        >
          <div className="container">
            <div className="inner-banner-content">
              <h3 className="inner-title">Study in USA</h3>
            </div>
          </div>
        </div>
      </section>
      <section className="home-course-section" style={{ background: "white" }}>
        <div className="container">
          <div className="overlay-wrapper">
            <div className="inner-course-wrap">
              <div className="row d-flex justify-content-center justify-content-xl-start">
                <div className="col-sm-6 col-lg-4 pe-3 ps-3 pt-4 pt-lg-0 inner-course">
                  <article className="post">
                    <figure className="feature-image">
                      <img src="assets/img/How-to.jpg" alt="" />
                      <Link to="/" className="bookmark-icon">
                        <i aria-hidden="true" className="far fa-bookmark" />
                      </Link>
                    </figure>
                    <div className="entry-content">
                      <h4>
                        <Link to="">Study in the USA</Link>
                      </h4>
                      <p>
                        USA in itself is an attractive package for Indian
                        students – it is home to the best universities, fortune
                        500 companies, job opportunities, and a land of immense
                        diversity. USA is the largest global economy and one of
                        the strongest countries in the world. People from
                        different countries have migrated to the Unites States
                        of America to live the American Dream. Cultures and
                        traditions brought to the land by different ethnicities
                        have contributed largely to the rich diversity the
                        country boast of.
                      </p>
                    </div>
                  </article>
                </div>
                <div className="col-sm-6 col-lg-4 pe-3 ps-3 pt-4 pt-lg-0 inner-course">
                  <article className="post">
                    <figure className="feature-image">
                      <img src="assets/img/US-Student.png" alt="" />
                      <Link to="" className="bookmark-icon">
                        <i aria-hidden="true" className="far fa-bookmark" />
                      </Link>
                    </figure>
                    <div className="entry-content">
                      <h4>
                        <Link to="">Why study in USA?</Link>
                      </h4>
                      <p>
                        Over 1, 90,000 students choose the USA as a preferred
                        destination to learn. USA is also the country with the
                        largest number of international student studying on its
                        soil. 18 percent of students studying in the USA are
                        Indians; Indian students form the second largest group
                        of international students in the USA.
                      </p>
                    </div>
                  </article>
                </div>
                <div className="col-sm-6 col-lg-4 pe-3 ps-3 pt-4 pt-lg-0 inner-course">
                  <article className="post">
                    <figure className="feature-image">
                      <img src="assets/img/why-study-usa.jpg" alt="" />
                      <Link to="" className="bookmark-icon">
                        <i aria-hidden="true" className="far fa-bookmark" />
                      </Link>
                    </figure>
                    <div className="entry-content">
                      <h4>
                        <Link to="">Quality of Education</Link>
                      </h4>
                      <p>
                        The USA has more universities than any other country in
                        the world. The country sees an enrollment of a million
                        international students each year. Studying in the USA
                        gives students the access to recourses, innovative
                        learning methods, technology, and the chance to learn
                        from some of the best minds in the world. Universities
                        and colleges in the US train students in smaller class
                        sizes, typically 10-20 students per class. A smaller
                        class size translates to better attention a student
                        receives. While in class students are encouraged to ask
                        questions, initiate and contribute to discussions.
                      </p>
                    </div>
                  </article>
                </div>
              </div>
            </div>
            <div className="overlay overlay-circle" />
            <div className="pattern-overlay" />
          </div>
        </div>
      </section>
    </>
  );
}

export default USA