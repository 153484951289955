import React from "react";

const HowSelectStudyLocation = () => {
  return (
    <>
      <section className="inner-banner-wrap">
        <div
          className="inner-baner-container"
          style={{ backgroundImage: "url(assets/img/educator-img12.jpg)" }}
        >
          <div className="container">
            <div className="inner-banner-content">
              <h3 className="inner-title">How to select your study location</h3>
            </div>
          </div>
        </div>
      </section>

      <section className="home-goal-section categories-goal-section">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-7">
              <div className="inner-goal-image">
                <figure className="video-play-image">
                  <img src="assets/img/tired.jpg" alt="" />
                  <div className="overlay02 overlay" />
                </figure>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="goal-content">
                <div className="pattern-overlay" />
                <div className="title-divider" />
                <p className="goal-info">
                  Moving overseas to study as a young student is an
                  unforgettable experience. One of the largest decisions you
                  will ever have to make, it is crucial to spend enough time
                  learning about each country's advantages and disadvantages as
                  well as the standard of living you can expect from each one.
                </p>
                <p className="goal-info">
                  What you want to study is one of the first things you should
                  ask yourself. Create a list of your strengths and interests
                  before limiting your options.
                </p>
                <p className="goal-info">
                  Check into the many institutions that offer your desired
                  course at multiple locations. Compare elements such as length,
                  subjects covered, pedagogies, costs, admission timetable, and
                  employment benefits.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="mt-lg-5"></div>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
    </>
  );
};

export default HowSelectStudyLocation;
