import React from 'react'
import { Link } from "react-router-dom";


const BusinessManagement = () => {
  return (
    <>
      <main id="content" className="site-main">
        <section className="inner-banner-wrap mb-0">
          <div
            className="inner-baner-container"
            style={{
              backgroundImage: "url(assets/img/Business-Management-1.png)",
            }}
          >
            <div className="container">
              <div className="inner-banner-content">
                <h3 className="inner-title">Business & Management</h3>
              </div>
            </div>
          </div>
        </section>
        <section className="course-result-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 left-content">
                <div className="pattern-overlay zigzag-patten" />
                <div className="feature-left-content">
                  <div className="title-divider" />
                  <h2 className="feature-course-title">
                    Business & Management !
                  </h2>
                  <p className="feature-course-desc">
                    Business refers to the activity of producing, buying, and
                    selling goods or services to make a profit. It involves
                    various functions such as marketing, finance, operations,
                    human resources, and strategic planning.
                  </p>
                  <p className="feature-course-desc">
                    Management involves the coordination and organization of
                    resources, including people, money, and materials, to
                    achieve organizational goals effectively and efficiently. It
                    includes activities such as planning, organizing, leading,
                    and controlling.
                  </p>
                  <p className="feature-course-desc">
                    Business strategy involves the long-term planning and
                    decision-making process that defines the direction and scope
                    of an organization. It encompasses competitive positioning,
                    target markets, value proposition, and resource allocation
                    to achieve sustainable competitive advantage.
                  </p>
                  <div className="feature-course-btn mt-lg-5">
                    <Link to="/contact" className="button-round-secondary">
                      JOIN US NOW
                    </Link>
                  </div>
                  <br />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="feature-right-content">
                  <div className="pattern-overlay c-patten" />
                  <div className="pattern-overlay circle-patten" />
                  <figure className="feature-image">
                    <img src="assets/img/Business-Management-1.png" alt="" />
                  </figure>
                  <br />
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default BusinessManagement