import React from "react";
import { Link } from "react-router-dom";

const Committed = () => {
  return (
    <>
      <div>
        <section className="home-goal-section">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-5">
                
                <div className="goal-content">
                  <div className="pattern-overlay" />
                  <div className="title-divider" />
                  <h2 className="goal-title">
                    Committed To The Best Results !
                  </h2>
                  <p className="goal-info">
                    we have succeeded in building a strong student network of
                    10k+ across the globe.
                  </p>
                  <Link to="/contact" className="button-round-secondary">
                    JOIN US NOW
                  </Link>
                </div>
              </div>
              <div className="col-lg-7">
                <div className="inner-goal-image">
                  <figure className="video-play-image">
                    <img src="assets/img/educator-img13.jpg" alt />
                    <div className="overlay02 overlay" />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="home-progress-section">
          <div className="overlay" />
          <div className="container">
            <div className="counter-inner">
              <div className="counter-item-wrap row">
                <div className="col-lg-3 col-sm-6 counter-item">
                  <div className="counter-no">
                    <span className="counter">45</span>k+
                  </div>
                  <div className="Completed">Active Students</div>
                </div>
                <div className="col-lg-3 col-sm-6 counter-item">
                  <div className="counter-no">
                    <span className="counter">20</span>+
                  </div>
                  <div className="Completed">Faculty Courses</div>
                </div>
                <div className="col-lg-3 col-sm-6 counter-item">
                  <div className="counter-no">
                    <span className="counter">190</span>+
                  </div>
                  <div className="Completed">Best Professors</div>
                </div>
                <div className="col-lg-3 col-sm-6 counter-item">
                  <div className="counter-no">
                    <span className="counter">35</span>
                  </div>
                  <span className="Completed">Award Achieved</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Committed;
