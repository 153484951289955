import React from "react";

const TrainingEducasa = () => {
  return (
    <>
      <section className="inner-banner-wrap">
        <div
          className="inner-baner-container"
          style={{ backgroundImage: "url(assets/img/start-up-busines.jpg)" }}
        >
          <div className="container">
            <div className="inner-banner-content">
              <h3 className="inner-title">Training Educasa</h3>
            </div>
          </div>
        </div>
      </section>
      <section className="home-about-us">
        <div className="container">
          <div className="row">
            <div className="col-md-6 ">
              <div className="about-left-content">
                <figure className="figure-round-border">
                  <img src="assets/img/start-up-busines.jpg" alt="" />
                </figure>

                <div className="title-divider" />
                <p className="about-desc">
                  Evaluate and assess their work, different institutions and
                  colleges around the world employ various methodologies and
                  standards. It is challenging to place all the students
                  entering one level from multiple educational systems because
                  these marking methods vary even among colleges in the same
                  nation. Entrance tests help level the playing field for all
                  students so that everyone can see how each one does in verbal
                  and mathematical analyses. As a result, practically all
                  prestigious colleges need the SAT/ACT for bachelor’s
                  programmes, the GRE for technical Master's degrees, and the
                  GMAT for Postgraduate Management programmes. Many university
                  selection procedures begin with these admission exams, though
                  they are occasionally taken in addition to earlier
                  accomplishments.
                </p>

                <p className="about-desc">
                  Students are expected to produce confirmation of English
                  competence in Reading, Writing, Listening, and Speaking
                  through either IELTS/TOEFL/PTE because the education they wish
                  to pursue is in a nation where English is the native language
                  or at least the language of instruction.
                </p>
              </div>
            </div>

            <div className="col-md-6">
              <div className=" about-right-content">
                <p className="about-desc">
                  IELTS or PTE scores are favoured in the UK, Australia, New
                  Zealand, and Canada while TOEFL is preferable when studying in
                  the USA and Germany.
                </p>
                <br />
                <h6 className="about-title">
                  Education at Educasa (Salient Features and Why Educasa)
                </h6>
                <p>
                  Students can sign up for Educasa to receive training and
                  counselling. We have a lengthy and demanding training
                  schedule. Our instructors are the best in the business; they
                  are graduates and postgraduates from India and abroad with GRE
                  scores between 320 and 330. To accommodate the demands of the
                  students and even during breaks, we offer convenient Weekday
                  and Weekend batches.
                </p>
                <figure className="figure-round-border">
                  <img src="assets/img/excited-young.jpg" alt="" />
                </figure>
                <br />
              </div>
            </div>
          </div>
          <div className="pattern-overlay" />
        </div>
      </section>
    </>
  );
};

export default TrainingEducasa;
