import React from 'react'
import { Link } from "react-router-dom";


const LawLegalStudies = () => {
  return (
    <>
      <main id="content" className="site-main">
        <section className="inner-banner-wrap mb-0">
          <div
            className="inner-baner-container"
            style={{ backgroundImage: "url(assets/img/busy-confident.jpg)" }}
          >
            <div className="container">
              <div className="inner-banner-content">
                <h3 className="inner-title">Law and Legal Studies</h3>
              </div>
            </div>
          </div>
        </section>
        <section className="course-result-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 left-content">
                <div className="pattern-overlay zigzag-patten" />
                <div className="feature-left-content">
                  <div className="title-divider" />
                  <h2 className="feature-course-title">
                    Law and Legal Studies !
                  </h2>
                  <p className="feature-course-desc">
                    Law is a system of rules and regulations that govern human
                    behavior and interactions within a society. Legal studies,
                    on the other hand, refer to the academic discipline that
                    focuses on the study of law and its application.
                  </p>
                  <p className="feature-course-desc">
                    Law and Legal Studies cover a wide range of subfields,
                    including constitutional law, criminal law, civil law,
                    administrative law, international law, environmental law,
                    family law, contract law, labor law, and more. Each subfield
                    focuses on specific areas of law and legal principles.
                  </p>
                  <p className="feature-course-desc">
                    Law and Legal Studies are typically studied at the
                    undergraduate and graduate levels. Students can pursue a
                    Bachelor of Laws (LLB) or a Juris Doctor (JD) degree, which
                    are required for practicing law in many jurisdictions. Other
                    degrees, such as a Bachelor of Arts (BA) or a Master of Laws
                    (LLM), can provide a broader understanding of legal
                    principles without necessarily leading to legal practice.
                    Legal studies can also be pursued as a standalone academic
                    discipline.
                  </p>
                  <div className="feature-course-btn mt-lg-5">
                    <Link to="/contact" className="button-round-secondary">
                      JOIN US NOW
                    </Link>
                  </div>
                  <br />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="feature-right-content">
                  <div className="pattern-overlay c-patten" />
                  <div className="pattern-overlay circle-patten" />
                  <figure className="feature-image">
                    <img src="assets/img/Law and Legal Studies.WEBP" alt="" />
                  </figure>
                  <br />
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default LawLegalStudies